import './bootstrap';

/**
 * External dependencies
 */
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { InstantSearch, Configure, InstantSearchSSRProvider } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';

/**
 * Internal dependencies
 */
import { DrawersContextProvider } from '@/common/contexts/use-drawers';
import Sentry, { initializeSentry } from '@/services/sentry/sentry';
import { QuickSearchProvider } from './common/contexts/use-quick-search';
import loadFontAwesome from './common/helpers/load-fontawesome';
import { ModalProvider } from './common/blocks/modal-manager/modal-manager';
import { AsidesProvider } from './common/contexts/use-asides';
import { ASIDES } from './frontdesk/constants/asides';

initializeSentry();
loadFontAwesome();

const queryClient = new QueryClient();
const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

const searchClient = algoliasearch('S9R3F5YAVQ', 'e91f75037b34deab2d2de772f5c1d950');

createInertiaApp({
    resolve: (name) => {
        if (typeof name === 'undefined') {
            return resolvePageComponent('./Pages/Frontdesk/PropertyHome.jsx', import.meta.glob('./Pages/**/*.jsx'));
        }

        return resolvePageComponent(`./Pages/Frontdesk/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
    },
    setup({ _, App, props }) {
        const user = props.initialPage.props.auth.user;
        if (user) {
            Sentry.setUser({ email: user.email, username: user.name });
        }

        const serverState = {
            initialResults: {},
        };

        root.render(
            <StrictMode>
                <QueryClientProvider client={queryClient}>
                    <DrawersContextProvider>
                        <InstantSearchSSRProvider {...serverState}>
                            <InstantSearch searchClient={searchClient} indexName="products">
                                <Configure filters="NOT visibility:HOST_ONLY" />

                                <QuickSearchProvider>
                                    <ModalProvider>
                                        <AsidesProvider asides={ASIDES}>
                                            <App {...props} />
                                        </AsidesProvider>
                                    </ModalProvider>
                                </QuickSearchProvider>
                            </InstantSearch>
                        </InstantSearchSSRProvider>
                    </DrawersContextProvider>
                </QueryClientProvider>
            </StrictMode>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
